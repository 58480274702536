<template>
    <OffCanvas>
        <template v-slot:heading>
            <Icon icon="vital_signs" class="me-2" />Record Activity
        </template>
        <form @submit.prevent="save()" v-if="! loading">
            <div class="mb-4">
                <AccountSearch
                    label="Which account are you recording against?"
                    :selected="quickActivityForm.activeAccount"
                    @update="(value: IAccount) => activity.account_id = value.id"
                />
            </div>

            <AccountContactSearch
                v-if="activity.account_id"
                :account-id="activity.account_id"
                :selected="quickActivityForm.activeContacts"
                @update="(value: IContact[]) => activity.contact_ids = value.map(u => u.id)"
            />

            <div v-if="activity.contact_ids.length">
                <div class="mb-4">
                    <label class="form-label">Type</label>
                    <div class="row row-cols-2 row-cols-md-3">
                        <template v-for="activityType in types" :key="activityType.id">
                            <div v-if="activityType.loggable" class="col mb-4">
                                <input type="radio" v-model="activity.activity_type_id" :id="'activityType-'+activityType.id" :value="activityType.id" autocomplete="off" class="btn-check">
                                <label :for="'activityType-'+activityType.id" class="h-100 btn p-3 d-block" :class="{
                                    'border-secondary': activity.activity_type_id !== activityType.id,
                                    'btn-success': activity.activity_type_id === activityType.id
                                }">
                                    <ActivityTypeIcon :type="activityType" />
                                    {{ activityType.name }}</label>
                            </div>
                        </template>
                    </div>
                </div>

                <div v-if="activity.activity_type_id">
                    <!-- TODO: Update to searchable FormElementSelect -->
                    <FormElementBasicSelect
                        label="Category"
                        :selected="activity.activity_category_id"
                        :options="categories.map((c: IActivityCategory) => {
                            return { value: c.id, text: c.name }
                        })"
                        :is-required="true"
                        @update="(value: any) => activity.activity_category_id = value"
                    />

                    <FormElementTextArea
                        label="Description"
                        class="m-0"
                        :content="activity.description"
                        :is-required="true"
                        @update="(value: string) => activity.description = value"
                    />

                    <UserSearch
                        label="Which other users were involved?"
                        text="These are the other users involved in this activity."
                        @update="(value: IUser[]) => activity.user_ids = value.map(u => u.id)"
                    />

                    <ActivitySentimentSelection
                        :label="`Velcrometer <small>&trade;</small> <small class='text-muted'>&ndash; &copy; KDawg ${(new Date).getFullYear()}`"
                        text="Which emoji best describes the customer&rsquo;s sentiment during this interaction?"
                        :selected="activity.relationship_rating"
                        @update="(value: number) => activity.relationship_rating = value"
                    />

                    <hr>

                    <UserSearch
                        label="Which users need to take action on this activity?"
                        @update="(value: IUser[]) => tagged_action_user_ids = value.map(u => u.id)"
                    />

                    <UserSearch
                        label="Which users do you want to read this activity?"
                        @update="(value: IUser[]) => tagged_read_user_ids = value.map(u => u.id)"
                    />

                    <hr>

                    <button type="submit"
                        class="btn btn-lg btn-primary w-100 fs-2"
                    >
                        <icon icon="save" class="me-2 inline" />
                        Save
                    </button>
                </div>
            </div>
        </form>
    </OffCanvas>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';

const { getActivityTypes, loadActivityCategories } = useActivityAttributes();
const quickActivityForm = useQuickActivityFormStore();
const toasts = useToastsStore();
const v$ = useVuelidate();

const categories = ref<IActivityCategory[]>([]);
const loading = ref(true);
const types = getActivityTypes;
const tagged_action_user_ids = ref<number[]>([]);
const tagged_read_user_ids = ref<number[]>([]);

const activity = reactive<IActivity>({
    activity_type_id: null,
    activity_category_id: null,
    account_id: null,
    contact_ids: [],
    user_ids: [],
    title: '',
    description: '',
    relationship_rating: 50,
});

watch(() => activity.account_id, () => {
    activity.contact_ids = [];
});

onMounted(async () => {
    if(categories.value.length === 0) {
        categories.value = await loadActivityCategories();
    }

    loading.value = false;
});


watch(() => activity.activity_type_id, async () => {
    categories.value = await loadActivityCategories(activity.activity_type_id, true);
});

const save = async () => {
    if(v$.value.$invalid) {
        toasts.validationError('Check all form fields are completed correctly');
        v$.value.$touch();
        return;
    }

    const { data: saveResponse } = await useApiFetch('/api/accounts/'+activity.account_id+'/activities', {
        method: 'post',
        body: {
            ...activity,
            tagged_action_user_ids: tagged_action_user_ids.value,
            tagged_read_user_ids: tagged_read_user_ids.value,
        }
    });

    if (saveResponse.value) {
        quickActivityForm.toggle();
        const accountId = activity.account_id;
        reset();
        await navigateTo({ name: 'accounts-show', params: { id: accountId }, hash: '#activities'});
    }
};

const reset = async () => {
    activity.activity_type_id = 1;
    activity.activity_category_id = null;
    activity.contact_ids = [];
    activity.user_ids = [];
    activity.title = '';
    activity.description = '';
    activity.relationship_rating = 50;
    tagged_action_user_ids.value = [];
    tagged_read_user_ids.value = [];
};
</script>
